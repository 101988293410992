import axios from 'axios'


const instance = axios.create({
    baseURL: 'https://ms-chatv3-prod.digitalk.com.br/api/v1',
    headers: {
        'Content-Type': 'application/json'
    }
})

export const instanceWidget = axios.create({
    baseURL: 'https://ms-widgetv2-prod.digitalk.com.br/api/v1',
    headers: {
        'Content-Type': 'application/json'
    }
})


instance.interceptors.request.use(
    config => {
        config.headers.Authorization = window.chatToken
        return config
    },
    error => Promise.reject(error)
)

export default instance