import React, {useEffect, useState, Suspense} from 'react'
import {createGlobalStyle} from "styled-components"
import {Normalize} from 'styled-normalize'
import ProximaLight from './styles/fonts/ProximaNovaLight.otf'
import ProximaSemi from './styles/fonts/ProximaNovaSemibold.otf'
import {getByCompanyToken} from "./api/chat";
import { ToastContainer } from 'react-toastify';

let WidgetButtons = React.lazy(() => import('./component/widget-buttons'));

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Proxima";
        font-weight: 500;
        src: url('${ProximaLight}') format('opentype');
    }
    
    @font-face {
        font-family: "Proxima";
        font-weight: bold;
        src: url('${ProximaSemi}') format('opentype');
    }
`

function App() {
    const [option, setOption] = useState(false)
    useEffect(() => {
        const fn = async () => {
            const {data} = await getByCompanyToken(window.digitalkToken)
            setOption( {
                name: data.name,
                widgetButtons: data.theme.widgetButtons.map(x => {
                    if (x.chatState) {
                        window.chatToken = x.token
                        return {...x, chatState: {messages: []}}
                    } else {
                        return x
                    }
                })
            })
        }
        fn()
    }, [])

    return <>
        <GlobalStyle/>
        <Normalize/>
        {option && <Suspense fallback={<span/>}>
            <WidgetButtons widgetButtons={option.widgetButtons}/>
        </Suspense>}
        <ToastContainer position="bottom-right"
                        autoClose={1500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>
    </>
}

export default App
